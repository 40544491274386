import PageLayout from "src/components/layout/index";
import "./App.css";

const App = () => {
  return (
    <div className="App">
      <PageLayout />
    </div>
  );
};

export default App;
