const i18n = {
  "en-US": {
    "header.home": "Home",
    "header.service": "Service",
    "header.case": "Case Study",
    "header.corporateinformation": "Company",
    "header.recruitmentinformation": "Recruit",
    "header.inquiry": "Contacts",
  },
  "zh-CN": {
    "header.home": "主页",
    "header.service": "服务内容",
    "header.case": "导入案例",
    "header.corporateinformation": "公司信息",
    "header.recruitmentinformation": "招聘信息",
    "header.inquiry": "联系方式",
  },
  "ja-JP": {
    "header.home": "ホーム",
    "header.service": "サービス内容",
    "header.case": "導入事例",
    "header.corporateinformation": "企業情報",
    "header.recruitmentinformation": "採用情報",
    "header.inquiry": "お問い合わせ",
  },
};

export default i18n;
