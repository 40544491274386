import { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ConfigProvider } from "@arco-design/web-react";
import enUS from "@arco-design/web-react/es/locale/en-US";
import zhCN from "@arco-design/web-react/es/locale/zh-CN";
import jaJP from "@arco-design/web-react/es/locale/ja-JP";
import { GlobalContext } from "./context";
import useStorage from "./utils/useStorage";
import changeTheme from "./utils/changeTheme";

function Index() {
  const [lang, setLang] = useStorage("arco-lang", "ja-JP");
  const [theme, setTheme] = useStorage("arco-theme", "light");

  const contextValue = {
    lang,
    setLang,
    theme,
    setTheme,
  };

  useEffect(() => {
    changeTheme(theme);
  }, [theme]);

  function getArcoLocale(lang?: string) {
    switch (lang) {
      case "zh-CN":
        return zhCN;
      case "en-US":
        return enUS;
      case "ja-JP":
        return jaJP;
      default:
        return zhCN;
    }
  }

  return (
    <ConfigProvider
      locale={getArcoLocale(lang)}
      componentConfig={{
        Card: {
          bordered: false,
        },
        List: {
          bordered: false,
        },
        Table: {
          border: false,
        },
      }}
    >
      <GlobalContext.Provider value={contextValue}>
        <App />
      </GlobalContext.Provider>
    </ConfigProvider>
  );
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<Index />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
