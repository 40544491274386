import { Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Layout, Spin } from "@arco-design/web-react";
import Header from "./header/index";
import Footer from "./footer/index";
import styles from "./index.module.less";
import lazyload from "./lazyload";

const Home = lazyload(() => import("src/pages/home"));
const Image = lazyload(() => import("src/pages/image"));
const Chat = lazyload(() => import("src/pages/chat"));

const Content = Layout.Content;

const PageLayout = () => {
  return (
    <Layout className={styles["layout"]}>
      <BrowserRouter>
        <Layout>
          <Header />
          <Content className={styles["layout-content"]}>
            <Suspense fallback={<Spin dot />}>
              <Routes>
                <Route key="/" path="/" element={<Home />} />
                <Route key="/image" path="/image" element={<Image />} />
                <Route key="/chat" path="/chat" element={<Chat />} />
                <Route key="*" path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </Suspense>
          </Content>
          <Footer />
        </Layout>
      </BrowserRouter>
    </Layout>
  );
};

export default PageLayout;
