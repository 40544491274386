import { useEffect, useState } from "react";
import { deviceWidthInPixel } from "src/const/device";
import debounce from "lodash/debounce";

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<any>({
    width: undefined,
    height: undefined,
  });
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });

      if (window.innerWidth < deviceWidthInPixel.xs) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    const debouncedHandleResize = debounce(() => handleResize(), 200);

    handleResize();

    window.addEventListener("resize", debouncedHandleResize);

    return () => window.removeEventListener("resize", debouncedHandleResize);
  }, []);

  return { windowSize, isMobile };
};

export default useWindowSize;
