export const deviceWidthInPixel = {
  xs: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
  xxxl: 2000,
};

export const device = {
  xsMaxWidth: `(max-width: ${deviceWidthInPixel.xs}px)`,
  mdMaxWidth: `(max-width: ${deviceWidthInPixel.md}px)`,
  lgMaxWidth: `(max-width: ${deviceWidthInPixel.lg}px)`,
  xlMaxWidth: `(max-width: ${deviceWidthInPixel.xl}px)`,
  xxlMaxWidth: `(max-width: ${deviceWidthInPixel.xxl}px)`,
  xxxlMaxWidth: `(max-width: ${deviceWidthInPixel.xxxl}px)`,
};
