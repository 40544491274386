import { useContext, useState, useEffect, useRef } from "react";
import {
  Avatar,
  Select,
  Dropdown,
  Menu,
  Divider,
  Button,
  Layout,
} from "@arco-design/web-react";
import {
  IconUser,
  IconSettings,
  IconPoweroff,
  IconExperiment,
  IconDashboard,
  IconInteraction,
  IconLanguage,
} from "@arco-design/web-react/icon";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import { GlobalContext } from "src/context";
import logo from "src/assets/pngs/logo.png";
import styles from "./index.module.less";
import useLocale from "src/utils/useLocale";
import { IconMenuFold, IconMenuUnfold } from "@arco-design/web-react/icon";
import locale from "./locale";
import useWindowSize from "src/hooks/useWindowSize";
import { gsap } from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

const MenuItem = Menu.Item;

const hideHeaderRoutes: { path: string }[] = [];
const DEFAULT_SELECTED_KEYS = ["/welcome"];

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const route = matchRoutes(hideHeaderRoutes, location);
  const { setLang, lang, theme, setTheme } = useContext(GlobalContext);
  const [selectedKeys, setSelectedKeys] = useState<string[]>(
    DEFAULT_SELECTED_KEYS
  );
  const t = useLocale(locale);
  const [collapse, setCollapse] = useState(true);
  const { isMobile } = useWindowSize();
  const avatarRef = useRef<any>();
  const onMenuItemClick = () => {};

  useEffect(() => {
    if (isMobile) {
      setCollapse(true);
    } else {
      setCollapse(false);
    }
  }, [isMobile]);

  const goToSlide = (index: number) => {
    gsap.to(window, {
      duration: 1,
      scrollTo: { y: "#section" + index, offsetY: 64 },
    });
  };

  const handleMenuClick = (index: number, key: string) => {
    if (isMobile) {
      setCollapse(true);
    } else {
      setCollapse(false);
    }
    goToSlide(index);
    setSelectedKeys([key]);
  };

  const droplist = (
    <Menu onClickMenuItem={onMenuItemClick}>
      <Menu.Item key="account">
        <IconUser className={styles["dropdown-icon"]} />
        xxx
      </Menu.Item>
      <Menu.Item key="setting">
        <IconSettings className={styles["dropdown-icon"]} />
        setting
      </Menu.Item>
      <Menu.SubMenu
        key="more"
        title={
          <div style={{ width: 80 }}>
            <IconExperiment className={styles["dropdown-icon"]} />
            seeMore
          </div>
        }
      >
        <Menu.Item key="workplace">
          <IconDashboard className={styles["dropdown-icon"]} />
          workplace
        </Menu.Item>
        <Menu.Item key="card list">
          <IconInteraction className={styles["dropdown-icon"]} />
          cardList
        </Menu.Item>
      </Menu.SubMenu>

      <Divider style={{ margin: "4px 0" }} />
      <Menu.Item key="logout">
        <IconPoweroff className={styles["dropdown-icon"]} />
        logout
      </Menu.Item>
    </Menu>
  );

  return !route ? (
    <Layout.Header className={styles["navbar"]}>
      {isMobile ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            style={{
              height: "100%",
            }}
            type="primary"
            onClick={() => setCollapse(!collapse)}
          >
            {collapse ? <IconMenuUnfold /> : <IconMenuFold />}
          </Button>
        </div>
      ) : null}
      <div
        className={styles["left"]}
        onClick={() => {
          navigate("/");
        }}
      >
        <div style={{ paddingLeft: 20 }} className={styles["logo"]}>
          <img
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              overflow: "hidden",
            }}
            alt="Anna"
            src={logo}
          />
        </div>
        {isMobile ? null : (
          <div className={styles["logo-text"]}>
            <span className={styles["logo-span"]}>ANNA GROUP</span>
          </div>
        )}
      </div>
      <div className={styles["menu"]}>
        <Menu
          style={{ display: collapse ? "none" : "inherit" }}
          className={isMobile ? styles["mobile-menu"] : ""}
          mode={isMobile ? "vertical" : "horizontal"}
          collapse={collapse}
          defaultSelectedKeys={DEFAULT_SELECTED_KEYS}
          selectedKeys={selectedKeys}
        >
          <MenuItem
            key="/welcome"
            onClick={() => {
              handleMenuClick(0, "/welcome");
            }}
          >
            {t["header.home"]}
          </MenuItem>
          <MenuItem
            key="/service"
            onClick={() => {
              handleMenuClick(1, "/service");
            }}
          >
            {t["header.service"]}
          </MenuItem>
          <MenuItem
            key="/case"
            onClick={() => {
              handleMenuClick(2, "/case");
            }}
          >
            {t["header.case"]}
          </MenuItem>
          <MenuItem
            key="/company"
            onClick={() => {
              handleMenuClick(3, "/company");
            }}
          >
            {t["header.corporateinformation"]}
          </MenuItem>
          <MenuItem
            key="/recruit"
            onClick={() => {
              handleMenuClick(4, "/recruit");
            }}
          >
            {t["header.recruitmentinformation"]}
          </MenuItem>
          <MenuItem
            key="/contact"
            onClick={() => {
              handleMenuClick(5, "/contact");
            }}
          >
            {t["header.inquiry"]}
          </MenuItem>
        </Menu>
      </div>

      <div className={styles["right"]}>
        <div className={styles["btn-container"]}>
          <Select
            triggerElement={
              <Button
                icon={<IconLanguage />}
                shape="circle"
                type="secondary"
                className={styles["icon-button"]}
              />
            }
            options={[
              { label: "日本語", value: "ja-JP" },
              { label: "English", value: "en-US" },
              { label: "中文", value: "zh-CN" },
            ]}
            triggerProps={{
              autoAlignPopupWidth: false,
              autoAlignPopupMinWidth: true,
              position: "br",
            }}
            trigger="hover"
            onChange={(value) => {
              setLang(value);
            }}
          ></Select>
        </div>
        {/* <div className={styles["btn-container"]}>
          <Dropdown droplist={droplist} position="br">
            <Avatar
              ref={avatarRef}
              size={32}
              style={{ cursor: "pointer" }}
            ></Avatar>
          </Dropdown>
        </div> */}
      </div>
    </Layout.Header>
  ) : null;
};

export default Header;
