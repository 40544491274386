import { useContext } from "react";
import { GlobalContext } from "../context";
import defaultLocale from "../locale";

function useLocale(locale: any = null) {
  const { lang } = useContext(GlobalContext);

  return (locale || defaultLocale)[lang as keyof typeof defaultLocale] || {};
}

export default useLocale;
