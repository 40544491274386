import { Layout } from "@arco-design/web-react";
import styles from "./index.module.less";

const Footer = () => {
  return (
    <Layout.Footer className={styles["footer"]}>
      <div>ⓒ Copyright Anna Investment Group 2022</div>
    </Layout.Footer>
  );
};

export default Footer;
